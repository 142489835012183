@import-normalize;

@import "./variables";
@import "./button";
@import "./imageLoader";
@import "./sizeSelector";
@import "./header";
@import "./productCard";
@import "./loader";
@import "./tabview";
@import "./loginPage";
@import "./styleSelector";
@import "./promptInput";
@import "./uploadImage";
@import "./featureBadge";
@import "./creativeSlider";
@import "./sandbox.scss";
@import "./brand.scss";
@import "./logo.scss";
@import "./toast.scss";

:root {
  font-family: $poppins, sans-serif;
  line-height: 1.3;
  font-weight: 400;
  font-size: 1rem;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background: $background;
  overflow-x: hidden;
  overscroll-behavior: none;
  min-height: 100dvh;
  min-width: 100dvw;
  color: $light-gray;
}

[data-theme="dark"] {
  $bg-color: $background;
  $bg-gradient: $background;
}

[data-theme="light"] {
  $bg-color: #ffffff;
  $bg-gradient: #ffffff;
}

h3 {
  font-weight: 500;
}

.text-green {
  color: $primary;
}

.text-red {
  color: $red;
}

.text-gray {
  color: $gray;
}

.toast-link {
  color: $dark-red;
}
