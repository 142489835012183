@import "./variables";

.style-selector {
  position: relative;
  min-height: 3rem;
  border: 2px solid $dark-gray;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.25rem;
  border-radius: $border-radius;
  outline: none;
  margin: 0.25rem auto;
  color: $light-gray;
  cursor: pointer;
  color: $gray;
  font-weight: 400;
  font-family: $poppins, sans-serif;
  z-index: 2;
  user-select: none;
  min-height: 3rem;
  background: $background;

  &:focus {
    border-color: $primary;
  }
}

.style-selector-value {
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

.style-selector-clear-btn {
  background: none;
  color: $gray;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 0.1rem;
  font-size: 1.2rem;
  margin: auto;
  transition: all 200ms ease;

  // &:focus,
  &:hover {
    color: $red;
    transition: color 200ms ease;
  }

  &:focus {
    color: $red;
    border-color: $red;
    transition: all 200ms ease;
  }
}

.tag-clear-btn {
  background: none;
  color: $gray;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  font-size: 1.25rem;
  transition: all 200ms ease;

  // &:focus,
  &:hover {
    color: $red;
    transition: color 200ms ease;
  }
}

.style-selector-divider {
  background: $dark-gray;
  align-self: stretch;
  width: 0.2rem;
}

.style-selector-caret {
  translate: 0 25%;
  border: 0.4rem solid transparent;
  border-top-color: $gray;
  transition: border 200ms ease;

  &:hover {
    border-top-color: $light-blue;
    transition: border 200ms ease;
  }
}

.style-selector-options {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  max-height: 15em;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid $dark-gray;
  border-radius: $border-radius;
  width: 100%;
  left: 0;
  top: calc(100% + 0.25em);
  background-color: $bg-color;
  transition: 100ms ease;
}

.style-selector-options-show {
  display: block;
}

.style-selector-option {
  padding: 0.25em 0.5em;
  cursor: pointer;
  transition: 100ms ease;
  font-size: 1rem;
  color: $light-gray;
}

.style-selector-option-selected {
  background-color: $orange;
  transition: 100ms ease;
  color: $dark-gray;
}

.style-selector-option-highlighted {
  background-color: $light-orange;
  color: $dark-gray;
  transition: 100ms ease;
}

.style-selector-option-badge {
  display: flex;
  align-items: center;
  border: 2px solid $dark-gray;
  border-radius: $border-radius;
  padding: 0 0.5rem;
  cursor: pointer;
  background: none;
  outline: none;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: $poppins;
  transition: 200ms ease;
  margin: 0;
  color: $outer-space-4;
  height: 2rem;

  &:hover,
  &:focus {
    transition: 200ms ease;
    background-color: $rich-black;
    border-color: $outer-space;
    color: #ffffff;
  }
}

.style-selector-choose-style {
  color: $outer-space-3;
  font-weight: 400;
  font-size: 1rem;
  font-family: $poppins, sans-serif;
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .style-selector {
    width: 60svw;
    margin: 0.25rem auto;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .style-selector {
    width: 50lvw;
    // margin: 1rem auto;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .style-selector {
    width: 35lvw;
    // margin: 1rem auto;
  }
}

.new-style-selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: auto;
}

.new-style-selector-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.5rem;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  box-shadow: 0 12px 16px 4px #000000bb;
}

.new-style-selector-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 4.5rem;
  width: 100%;
  padding: 0 2rem;
  background: #24202850;
  box-shadow: 0 -10px 16px 4px #000000cc;
}

.new-style-selector {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background: $rich-black;
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $charcoal;
    border-radius: 1rem;
  }
}

.new-style-selector-item {
  width: 100%;
  max-width: 120px;
  height: 120px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid $gray;
  outline: none;
  border-radius: $border-radius;

  overflow: hidden;
  // transition: all 100ms ease;

  img {
    width: 100%;
    height: 100px;
    margin: 0 auto;
    border-radius: 0;
  }

  p {
    font-family: $poppins;
    margin: auto;
    font-weight: 400;
    font-size: 0.7rem;
    color: $light-gray;
  }
}

.new-style-selector-item-selected {
  border: 2px solid $primary;
  background: $dark-gray;
  outline: none;
  // outline: 2px solid $primary;
  transform: translate3D(0, -1px, 0) scale(1.01);
  transition: all 50ms ease-in;
}

.new-style-selector-item-highlighted {
  filter: brightness(1.1);
  transform: translate3D(0, -1px, 0) scale(1.01);
  outline: none;
  // transition: all 50ms ease;
}

/* Medium devices */
@media only screen and (min-width: 325px) {
  .new-style-selector {
    grid-template-columns: auto auto auto;
  }
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .new-style-selector {
    grid-template-columns: auto auto auto;
  }

  .new-style-selector-item {
    p {
      font-size: 0.9rem;
    }
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .new-style-selector {
    grid-template-columns: auto auto auto auto;
  }
}
