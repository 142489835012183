.feature-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  margin: auto 0 auto 0.35rem;
  padding: 0 2px;
  border-radius: $border-radius;
  color: $rich-black-3;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: $poppins, sans-serif;
}
