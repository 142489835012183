@import "./variables";

.prompt-input-wrapper {
  margin: 0.25rem auto;
  display: flex;
  align-items: center;
  height: 3rem;
  // border: 2px solid $outer-space;
  outline: none;
  border-radius: $border-radius;
}

.prompt-input {
  width: 100%;
  height: 3rem;
  border: none;
  background: $background;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  outline: none;
  font-size: 1rem;
  font-family: $poppins, sans-serif;
  color: $outer-space-4;
  border: 2px solid $secondary;
  transition: 200ms border ease;

  &:focus {
    border-color: $primary;
  }

  &::placeholder {
    color: $outer-space-3;
    opacity: 1;
    user-select: none;
    font-weight: 400;
    font-family: $poppins, sans-serif;
  }
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .prompt-input-wrapper {
    width: 60svw;
    margin: 0.25 auto;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .prompt-input-wrapper {
    width: 50lvw;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .prompt-input-wrapper {
    width: 35lvw;
  }
}
