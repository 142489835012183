.tab-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.25rem 0 0 0;
}

.tab-bar {
  height: 3rem;
  display: flex;
  padding: 0 0.5rem;
  width: 100%;
  align-content: center;
  justify-content: space-evenly;
  font-family: $poppins;
}

.tab-bar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
}

.tab-bar-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid transparent;
  border-bottom: $rich-black-2 2px solid;
  color: $outer-space-5;
  font-weight: 400;
  font-size: 0.75rem;
  user-select: none;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.tab-bar-item-selected {
  background: $secondary;
  border: $gunmetal-2 2px solid;
  color: #ffffff;
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .tab-view {
    width: 60svw;
    margin: 0.25rem auto auto auto;
  }
  .tab-bar {
    margin: auto 0;
  }
  .tab-bar-item {
    font-size: 0.85rem;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .tab-view {
    width: 50lvw;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .tab-view {
    width: 35lvw;
  }
}
