@import "./variables";

.image-loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.image {
  user-select: none;
  width: auto;
  height: auto;
  margin: 0 auto auto auto;
  max-width: 100%;
  max-height: 100%;
  // Possible fixes
  display: block;
  object-fit: contain;
}

.canvas {
  position: absolute;
  top: 0;
  display: block;
  object-fit: contain;
  cursor: crosshair;
  overflow: hidden;
  filter: opacity(0.5) drop-shadow(1px 1px 1px black);
}

.download-image-button {
  margin: 1rem auto;
  padding: 1.1rem 0.5rem;
  background: $secondary;
  color: $outer-space-3;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  width: auto;
  font-size: 1rem;
  font-weight: 500;
  font-family: poppins, sans-serif;
  text-decoration: none;
  cursor: pointer;
  transition: filter 200ms ease;

  &:hover {
    filter: brightness(1.2);
    transition: filter 200ms ease;
  }
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .image-loader {
    width: 60svw;
    margin: 0.5rem auto;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .image-loader {
    width: 50lvw;
    margin: 0.5rem auto;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .image-loader {
    width: 35lvw;
    margin: 0.5rem auto;
  }
}
