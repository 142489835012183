@import "./variables";

$speed: 300ms;

.header {
  margin: 0;
  height: 4rem;
  width: 100%;
  background-color: $secondary;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
}

.header-wrapper {
  width: 100%;
  margin: auto;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  h1 {
    margin: auto auto auto 0.5rem;
    font-size: 1.4rem;
    line-height: 1.1;
    font-family: $poppins;
    font-weight: 600;
    user-select: none;
  }
  p {
    cursor: default;
    font-size: 0.65rem;
    font-family: $poppins;
    color: $outer-space-3;
    user-select: none;
    margin: auto 0.25rem;
  }
  a {
    display: flex;
  }
}

.settings-icon {
  margin: auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  outline: none;
  border: 2px solid transparent;

  &:focus {
    border-color: $primary;
  }
}

.modal-menu-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5em;
  outline: none;
  margin: auto 0.5rem;
}

.modal-menu {
  position: relative;
  background-color: $background;
  border-radius: $border-radius;
  border: 2px solid $background;
  width: 100%;
  margin: auto 1rem;
  max-width: 400px;
  height: 55dvh;
  box-shadow: #00000090 0px 5px 15px;
  overflow-y: hidden;
  font-size: 1rem;

  h2 {
    margin: 1rem auto;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.user-menu {
  height: auto;
}

.modal-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: $speed ease;
  z-index: 10;
}

.modal-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: $speed ease;
}

.modal-menu-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  background-color: #000000;
  transition: $speed ease;
}

.modal-menu-backdrop.active {
  opacity: 1;
  visibility: visible;
  z-index: 5;
}

.modal-menu-backdrop.inactive {
  opacity: 0;
  visibility: hidden;
}

.modal-menu ul {
  display: block;
  margin: 1rem 0;
}

.modal-menu ul li {
  padding: 0 1rem;
}

.modal-menu-top-container {
  background: $background;
}

.modal-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 4px auto;
}

.modal-item a {
  text-decoration: underline;
  color: $primary;
  margin-left: 4px;
}

.modal-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.25rem;
}

.avatar {
  border-radius: 600px;
}

.modal-avatar {
  border-radius: 600px;
  border: $outer-space 2px solid;
  margin: 1rem auto;
}

.modal-help-link-text {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: $primary;
  }

  p {
    color: $outer-space-4;
  }
}

hr,
.line-break {
  color: $rich-black-2;
  background: $rich-black-2;
  border: 1px solid $rich-black-2;
  margin: 0 0.25rem;
}

/* Medium devices */
@media only screen and (min-width: 480px) {
  .header-wrapper {
    p {
      font-size: 0.75rem;
    }
  }
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .modal-menu {
    max-width: 600px;
    height: 700px;
  }
  .user-menu {
    height: auto;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .new-style-selector {
    grid-template-columns: auto auto auto;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .new-style-selector {
    grid-template-columns: auto auto auto auto;
  }
}
