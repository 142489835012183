@import "./variables";

.product-card-wrapper {
  margin: 2rem auto;
  width: 75%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-size: 1.75rem;
    text-align: center;
    font-weight: 500;
  }
}

.product-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 1rem auto auto auto;
  text-align: center;
  width: auto;
  background: $rich-black-4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 2px $gunmetal solid;
  cursor: pointer;
  overflow: hidden;
  transition:
    background 0.3s ease,
    box-shadow 0.3s ease;

  img {
    width: 100%;
  }
  ul li {
    text-align: left;
  }
}

.price {
  color: $primary;
  font-size: 2.25rem;
  font-family:
    system-ui,
    -apple-system,
    sans-serif;
  font-weight: 500;
  text-align: center;
  margin: auto;
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .product-card-wrapper {
    width: 50%;
    height: auto;
    max-width: 500px;
  }

  .price {
    font-size: 2.5rem;
  }
}
