@import "./variables.scss";

.logo {
  width: 3.25rem;
  margin: auto 0;
}

.logo-login {
  width: 50%;
  margin: auto 0;
}

.logo-svg {
  animation: $logo-animation 10s infinite;
}
