@import "./variables";

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background: #000000;

  h1 {
    font-weight: 500;
  }
}

.login-page-wrapper {
  min-height: 450px;
  width: 70%;
  max-width: 400px;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 2px solid $background;
  border-radius: $border-radius;
  box-shadow: #000000af 1px 5px 10px;
  background: $background;
  opacity: 0.93;
}

.login-page-logo {
  // width: clamp(50px, 75px, 100px);
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-text {
  margin: 1rem;
  font-size: clamp(1.25rem, 1.5rem, 2rem);
  text-align: center;

  h3 {
    font-size: 1.5rem;
    font-family: $poppins;
  }

  a {
    color: $primary;
  }
}

.sign-in-button-wrapper {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
