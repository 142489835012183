$logo-animation: logo-color-change;
$brand-animation: brand-color-change;
$brand-animation-2: brand-color-change-2;

@keyframes brand-color-change {
  0% {
    color: $primary;
  }
  50% {
    color: #ffffff;
  }
  100% {
    color: $primary;
  }
}

@keyframes brand-color-change-2 {
  0% {
    color: #ffffff;
  }
  50% {
    color: $primary;
  }
  100% {
    color: #ffffff;
  }
}

@keyframes logo-color-change {
  0% {
    color: $outer-space-3;
  }
  50% {
    color: $outer-space-2;
  }
  100% {
    color: $outer-space-3;
  }
}
